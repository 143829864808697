import { template as template_203e3888980246528213fedb04aff7fa } from "@ember/template-compiler";
const FKLabel = template_203e3888980246528213fedb04aff7fa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
