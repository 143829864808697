import { template as template_b17ab02f47ad4bb589a5d2126748541e } from "@ember/template-compiler";
const FKControlMenuContainer = template_b17ab02f47ad4bb589a5d2126748541e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
