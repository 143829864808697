import { template as template_ed63f62271f245f68f2a2c2d91475c1b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_ed63f62271f245f68f2a2c2d91475c1b(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
